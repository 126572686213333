import {colors} from "@c10h/colors";
import {ReadableSlotTime} from "@components/ReadableSlotTime";
// @ts-expect-error TS7016: Could not find a declaration file for module 'color'. '/Users/goksel/Sites/carbon-website-next/node_modules/color/index.js' implicitly has an 'any' type.
import Color from "color";
import {useTranslation} from "ni18n";
import React from "react";
import {v5Pages} from "src/components/_common/_constants";
import {BtnProps} from "src/components/v5/V5Comp";
import {SpecialtyId} from "src/constants/specialtyIds";

import {todayTomorrow2} from "../components/_common/Carbon";
import {useGetSoonestInPracticeBySpecialtyId} from "../hooks/useGetSoonestTime";
import {useTypedSelector} from "../store";
import {getSchedulingRedirectUrl} from "../utils/getRedirectUrl";
import {PracticeId} from "./practiceIds";

export type ServicesMode = {
  header: string;
  subtext?: string;
  buttons?: BtnProps[];
  dotColor?: string;
  panelColor: string;
  listColor?: string;
  commonReasons?: (string | JSX.Element)[];
  bgImage?: string;
};

type Args = {
  practiceId?: string;
};

const useServicesConstants = ({practiceId = PracticeId.CARBON}: Args): {modes: ServicesMode[]} => {
  const i18n = useTranslation();
  const {locations} = useTypedSelector(({config}) => config);

  const primaryCareSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId: SpecialtyId.PRIMARY_CARE,
  });
  const urgentCareSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId: SpecialtyId.URGENT_CARE,
  });
  const virtualMentalSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId: SpecialtyId.VIRTUAL_MENTAL_HEALTH,
  });
  const virtualPCSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId: SpecialtyId.VIRTUAL_PRIMARY_CARE,
  });

  const primaryCareSlotRegionSlug = locations.findById(primaryCareSlot?.locationId || "")?.region
    ?.slug;
  const urgentCareSlotRegionSlug = locations.findById(urgentCareSlot?.locationId || "")?.region
    ?.slug;

  const learnMoreBtnProps: BtnProps = {
    href: "#featured-locations",
    children: i18n.t("Learn More"),
    className:
      "pos-r mr2 p4 mr0-sm mb6-sm fs14-f fs12-f-sm brd2nc-f brd-black-f black-f bg-gray50-f",
  };

  const getNextApptSlot = (keyPrefix: string, nextSlot?: number) => (
    <span
      key={`services-next-appt-${keyPrefix}`}
      className="pos-a font-im gray800 fs12 left0 right0 ma empty:hidden"
      style={{bottom: -20}}
    >
      {i18n.t("Next: ")} <ReadableSlotTime time={nextSlot} />
    </span>
  );

  const modes: ServicesMode[] = [
    {
      header: i18n.t("Primary Care"),
      subtext: i18n.t("Ideal for preventative care"),
      buttons: [
        {
          bg: colors.darkerBlue,
          hoverBg: colors.darkBlue,
          href: `${v5Pages.locations}?specialtyId=${SpecialtyId.PRIMARY_CARE}&regionSlug=${primaryCareSlotRegionSlug}`,
          children: [
            i18n.t("Book an Appointment"),
            getNextApptSlot("primary-care", primaryCareSlot?.time),
          ],
          "aria-label": i18n.t(
            `Book an Appointment for {{specialtyName}}. Next Available: {{slotTime}}`,
            {
              specialtyName: i18n.t("Primary Care"),
              slotTime: todayTomorrow2(i18n.lang, primaryCareSlot?.time),
            },
          ),
        },
        {
          ...learnMoreBtnProps,
          href: "/get-care/primary-care",
          "aria-label": i18n.t("Learn more about {{specialtyName}}", {
            specialtyName: i18n.t("Primary Care"),
          }),
        },
      ],
      panelColor: colors.blue,
      dotColor: colors.darkerBlue,
      listColor: Color(colors.darkerBlue).alpha(0.75),
      commonReasons: [
        i18n.t("Allergy Symptoms"),
        i18n.t("Back Pain"),
        i18n.t("Cold or Flu"),
        i18n.t("Red Eye"),
        i18n.t("Birth Control"),
        i18n.t("Hypertension"),
        i18n.t("Specialist Referral"),
        i18n.t("Diabetes"),
        i18n.t("Medication Refill"),
        i18n.t("Skin Rash"),
        i18n.t("Annual Physical"),
      ],
    },
    {
      header: i18n.t("Urgent Care"),
      subtext: i18n.t("Ideal for immediate needs"),
      buttons: [
        {
          bg: colors.darkerYellow,
          hoverBg: colors.darkYellow,
          href: `${v5Pages.locations}?specialtyId=${SpecialtyId.URGENT_CARE}&regionSlug=${urgentCareSlotRegionSlug}`,
          children: [
            i18n.t("Book an Appointment"),
            getNextApptSlot("urgent-care", urgentCareSlot?.time),
          ],
          "aria-label": i18n.t(
            `Book an Appointment for {{specialtyName}}. Next Available: {{slotTime}}`,
            {
              specialtyName: i18n.t("Urgent Care"),
              slotTime: todayTomorrow2(i18n.lang, urgentCareSlot?.time),
            },
          ),
        },
        {
          ...learnMoreBtnProps,
          href: "/get-care/urgent-care",
          "aria-label": i18n.t("Learn more about {{specialtyName}}", {
            specialtyName: i18n.t("Urgent Care"),
          }),
        },
      ],
      panelColor: colors.lightYellow,
      dotColor: colors.darkerYellow,
      listColor: Color(colors.darkerYellow).alpha(0.75),
      commonReasons: [
        i18n.t("UTI"),
        i18n.t("Headache"),
        i18n.t("Injuries"),
        i18n.t("Cuts"),
        i18n.t("STI"),
        i18n.t("Rash"),
        i18n.t("Cough"),
        i18n.t("Cold or Flu"),
        i18n.t("Fever"),
        i18n.t("Stomach Pain"),
        i18n.t("Red Eye"),
      ],
    },
    {
      header: i18n.t("Mental Health"),
      subtext: i18n.t("Video call with a provider"),
      buttons: [
        {
          bg: colors.darkerPurple,
          hoverBg: colors.darkPurple,
          href: getSchedulingRedirectUrl({
            practiceId,
            specialtyId: SpecialtyId.VIRTUAL_MENTAL_HEALTH,
            virtual: true,
          }),
          children: [
            i18n.t("Book an Appointment"),
            getNextApptSlot("mental-health", virtualMentalSlot?.time),
          ],
          "aria-label": i18n.t(
            `Book an Appointment for {{specialtyName}}. Next Available: {{slotTime}}`,
            {
              specialtyName: i18n.t("Mental Health"),
              slotTime: todayTomorrow2(i18n.lang, virtualMentalSlot?.time),
            },
          ),
        },
        {
          ...learnMoreBtnProps,
          href: "/virtual-care-mental",
          "aria-label": i18n.t("Learn more about {{specialtyName}}", {
            specialtyName: i18n.t("Mental Health"),
          }),
        },
      ],
      dotColor: colors.darkerPurple,
      panelColor: colors.lightPurple,
      listColor: Color(colors.darkerPurple).alpha(0.6),
      commonReasons: [
        i18n.t("Anxiety"),
        i18n.t("Depression"),
        i18n.t("Smoking Cessation"),
        i18n.t("Alcohol Use"),
        i18n.t("Weight Loss"),
        i18n.t("Stress"),
        i18n.t("Family Therapy"),
        i18n.t("ADHD"),
        i18n.t("Sadness"),
        i18n.t("Relationships"),
        i18n.t("Group Therapy"),
      ],
    },
    {
      header: i18n.t("Women's Health"),
      subtext: i18n.t("Women's health and wellness"),
      buttons: [
        {
          bg: colors.darkerMint,
          hoverBg: colors.darkMint,
          href: getSchedulingRedirectUrl({
            practiceId,
            specialtyId: SpecialtyId.VIRTUAL_PRIMARY_CARE,
          }),
          children: [
            i18n.t("Book an Appointment"),
            getNextApptSlot("womens-health", virtualPCSlot?.time),
          ],
          "aria-label": i18n.t(
            `Book an Appointment for {{specialtyName}}. Next Available: {{slotTime}}`,
            {
              specialtyName: i18n.t("Women's Health"),
              slotTime: todayTomorrow2(i18n.lang, virtualPCSlot?.time),
            },
          ),
        },
        {
          ...learnMoreBtnProps,
          href: v5Pages.womensHealth,
          "aria-label": i18n.t("Learn more about {{specialtyName}}", {
            specialtyName: i18n.t("Women's Health"),
          }),
        },
      ],
      dotColor: colors.darkerMint,
      panelColor: colors.darkGreen,
      listColor: Color(colors.lightMint).alpha(0.7),
      commonReasons: [
        i18n.t("UTI"),
        i18n.t("Breast Health"),
        i18n.t("Annual Physical"),
        i18n.t("Pelvic Exam"),
        i18n.t("Pap Smear"),
        i18n.t("Bone Density Exam"),
        i18n.t("Ultrasound"),
        i18n.t("Birth Control"),
        i18n.t("Skin Cancer"),
        i18n.t("Pregnancy Test"),
        i18n.t("Vaginal Infection"),
      ],
    },
    {
      header: i18n.t("LGBTQ+ Health"),
      subtext: i18n.t("Inclusive + judgment-free"),
      buttons: [
        {
          bg: colors.darkerPurple,
          hoverBg: colors.darkPurple,
          href: getSchedulingRedirectUrl({
            practiceId,
            specialtyId: SpecialtyId.VIRTUAL_PRIMARY_CARE,
          }),
          children: [i18n.t("Book an Appointment"), getNextApptSlot("lgbtq", virtualPCSlot?.time)],
          "aria-label": i18n.t(
            `Book an Appointment for {{specialtyName}}. Next Available: {{slotTime}}`,
            {
              specialtyName: i18n.t("LGBTQ+ Health"),
              slotTime: todayTomorrow2(i18n.lang, virtualPCSlot?.time),
            },
          ),
        },
        {
          ...learnMoreBtnProps,
          href: v5Pages.lgbtq,
          "aria-label": i18n.t("Learn more about {{specialtyName}}", {
            specialtyName: i18n.t("LGBTQ+ Health"),
          }),
        },
      ],
      dotColor: colors.darkerPurple,
      panelColor: colors.lighterPurple,
      commonReasons: [
        <span key="r1" className="darkerRed">
          {i18n.t("Annual Physical")}
        </span>,
        <span key="r2" className="red">
          {i18n.t("Pelvic Exam")}
        </span>,
        <span key="r3" className="red">
          {i18n.t("Chest Health")}
        </span>,
        <span key="r4" className="darkerYellow">
          {i18n.t("STI/HIV")}
        </span>,
        <span key="r5" className="darkerYellow">
          {i18n.t("Cancer Screening")}
        </span>,
        <span key="r6" className="darkerMint">
          {i18n.t("Ultrasound")}
        </span>,
        <span key="r7" className="darkerMint">
          {i18n.t("Depression")}
        </span>,
        <span key="r8" className="darkerBlue">
          {i18n.t("Anxiety")}
        </span>,
        <span key="r9" className="darkerBlue">
          {i18n.t("Pap Smear")}
        </span>,
        <span key="r10" className="darkerPurple">
          {i18n.t("Behavioral Health")}
        </span>,
        <span key="r11" className="darkerPurple">
          {i18n.t("UTI")}
        </span>,
      ],
    },
  ];

  return {
    modes,
  };
};

export default useServicesConstants;
