/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {useTranslation} from "ni18n";
import React from "react";
import {ServicesMode} from "src/constants/ServicesConstants";

import {Btn} from "./V5Comp";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  modes: ServicesMode[];
};

const MobileServiceCards: React.FC<Props> = ({modes}) => {
  const i18n = useTranslation();

  return (
    <section className="bg-gray50 hide-scroll-bars" aria-labelledby="services-heading">
      <h2 id="services-heading" className="visually-hidden">
        {i18n.t("Services")}
      </h2>
      <div className="dg pos-r pv14 pl3 pr3 ovf-x-a ovf-y-h">
        {modes
          .map(mode => (
            <div
              key={`mobile-service-card-${mode.header}`}
              className="df fdc tac br5 mr3 bs2 hfc oh w16rem"
            >
              <div className="df fdc pt7 mb1 ph7 aic">
                <h3 className="font-csb fs22 mb3 wsnw" style={{letterSpacing: 0.25}} tabIndex={0}>
                  {mode.header}
                </h3>
                <strong className="font-im fs12 mb4 wsnw">{mode.subtext}</strong>
                {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
                <Btn className="pos-r mb8" fontSize={0.75} {...mode.buttons[0]} />
                {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
                {mode.buttons[1] && (
                  <Btn
                    // @ts-expect-error TS2532: Object is possibly 'undefined'.
                    href={mode.buttons[1]?.href || "#learn-more"}
                    bg="transparent"
                    className="font-ib fs12"
                    // @ts-expect-error TS2532: Object is possibly 'undefined'.
                    color={mode.buttons[0]?.bg || mode.panelColor}
                    fontSize={0.75}
                    // @ts-expect-error TS2532: Object is possibly 'undefined'.
                    aria-label={mode.buttons[1]["aria-label"]}
                  >
                    {i18n.t("Learn More")}
                  </Btn>
                )}
              </div>
              <ul
                className="dg gafr gtc2a gg3 white fs13 font-im p3 aic"
                style={{backgroundColor: mode.panelColor, color: mode.listColor}}
              >
                {mode.commonReasons?.map((item, i) => (
                  <li key={`common-reason-${i}`} className="">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))
          .append(<div key="spacer" className="ph1" />)}
      </div>
    </section>
  );
};

export default MobileServiceCards;
